import { MobileTableRow } from 'components';
import { MobileTableV2, MobileTableRowField } from 'components/common/MobileTableV2';
import { useMobileTable } from 'hooks/useMobileTable';
import { MobileTableProps, WorkOrder } from 'types';
import { getFullName, toEnUsFormat } from 'utils';
import { links } from 'App';

export const WorkOrdersMobileTable = ({ items, loading, onClearFilters }: MobileTableProps<WorkOrder>) => {
  const { selectedRow, toggleSelectedRow } = useMobileTable();

  return (
    <MobileTableV2
      hasData={items.length > 0}
      loading={loading}
      isScrollTop={false}
      handleClearFilters={onClearFilters}
      model={'work orders'}
      className="mb-24"
    >
      {items.map((item: WorkOrder) => {
        const { id, status, dueDate, project, type, assignee, reportStatus, drafter, bank, bankContact, name } = item;
        const workOrderLink = links.WorkOrderOverview({ id });

        return (
          <MobileTableRow
            key={id}
            id={id}
            rowTitle={name}
            handleToggle={toggleSelectedRow}
            selectedRowInfo={selectedRow}
          >
            <MobileTableRowField label="Due date" value={toEnUsFormat(dueDate)} link={workOrderLink} />
            <MobileTableRowField label="Project name" value={project?.name} link={workOrderLink} />
            <MobileTableRowField label="Work Order type" value={type?.name} link={workOrderLink} />
            <MobileTableRowField label="Assignee" value={getFullName(assignee)} link={workOrderLink} />
            <MobileTableRowField label="Status" value={status?.name} link={workOrderLink} />
            <MobileTableRowField label="Draft status" value={reportStatus?.replace(/_/g, ' ')} link={workOrderLink} />
            <MobileTableRowField label="Drafter" value={getFullName(drafter)} link={workOrderLink} />
            <MobileTableRowField label="Bank" value={bank?.name} link={workOrderLink} />
            <MobileTableRowField label="Bank Contact" value={getFullName(bankContact)} link={workOrderLink} />
          </MobileTableRow>
        );
      })}
    </MobileTableV2>
  );
};
