/* eslint-disable @typescript-eslint/no-explicit-any */
import { ReactNode, useEffect, useState } from 'react';
import clsx from 'clsx';

import { grouper } from 'utils';
import { ArrowDownIcon } from 'icons';
import { DropdownValue } from 'types';

import './SearchSelect.scss';

type SearchSelectProps = {
  label: string;
  options: DropdownValue[];
  onChange: (value: string, name?: string) => void;
  fullWidth?: boolean;
  loading?: boolean;
};

const formatGroupLabel = (group: any) => <h1>{group.title}</h1>;

export const SearchSelect = ({ label, options = [], onChange, fullWidth, loading }: SearchSelectProps) => {
  const [isOpened, setIsOpened] = useState(false);
  const [select, setSelect] = useState<ReactNode | null>(null);

  const toggleOpen = () => setIsOpened(!isOpened);

  const onSelectChange = (selectedOption: any) => {
    onChange(selectedOption.value, selectedOption.label);
    toggleOpen();
  };

  useEffect(() => {
    const getSelect = async () => {
      const { default: Select } = await import('react-select');
      return { Select };
    };

    getSelect()
      .then(({ Select }) =>
        setSelect(
          <Select
            isLoading={loading}
            placeholder={'Search'}
            options={grouper(options)}
            className="react-select-container w-100"
            classNamePrefix="react-select"
            onChange={onSelectChange}
            isSearchable
            formatGroupLabel={formatGroupLabel}
            menuIsOpen
          />,
        ),
      )
      .catch(console.error);

    return () => {
      setSelect(null);
    };
  }, [isOpened]);

  return (
    <div className={clsx('searchSelectContainer', fullWidth && 'w-100')}>
      <div
        className={clsx(
          'flex justify-content-between align-items-center weight-600',
          'selectDropdown',
          isOpened && 'activeSelectDropdown',
        )}
        onClick={() => toggleOpen()}
      >
        <span className="mr-8">{label}</span> <ArrowDownIcon className="dropdownArrowDownIcon" />
      </div>
      {isOpened && <div className={clsx('selectMenuContainer', fullWidth && 'w-100')}> {select ? select : null}</div>}
    </div>
  );
};
