import { useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import clsx from 'clsx';
import { FieldAttributes } from 'formik';
import { CircularProgress } from '@material-ui/core';

// eslint-disable-next-line
export const RichEditor = (props: FieldAttributes<any>) => {
  const {
    field: { name },
    form: { setFieldValue, values },
    label,
    disabled,
    isSpellChecker = true,
    isShowLoader = false,
  } = props;

  // eslint-disable-next-line
  const editorRef = useRef<any>(null);
  const [editor, setEditor] = useState<unknown | null>(null);

  return (
    <>
      {!editor && isShowLoader && <CircularProgress size={64} />}
      <div className={clsx('p-relative', 'w-100')} style={{ display: !editor ? 'none' : 'initial' }}>
        <span className="mb-16 text-20">{label}</span>

        <Editor
          apiKey="hl1isxggsat8729yjt32vvc51ru4gqp95xwbgqryi8p869uq"
          onInit={(_, editor) => {
            setEditor(editor);
            editorRef.current = editor;
          }}
          onBlur={(_, editor) => {
            // console.log(editor.getContent());
            setFieldValue(name, editor.getContent());
          }}
          disabled={disabled}
          initialValue={values[name] ?? ''}
          init={{
            width: '100%',
            menubar: true,
            min_height: 250,
            max_height: 500,
            plugins: [
              'autoresize',
              'advlist',
              isSpellChecker ? 'tinymcespellchecker' : '',
              'autolink',
              'lists',
              'link',
              'charmap',
              'preview',
              'anchor',
              'searchreplace',
              'visualblocks',
              'code',
              'fullscreen',
              'insertdatetime',
              'media',
              'table',
              'help',
              'wordcount',
              'save',
              'powerpaste',
            ],
            toolbar:
              'undo redo | styles | bold italic | underline alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | spellchecker',
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
            image_title: true,
            automatic_uploads: true,
            file_picker_types: 'image',
          }}
        />
      </div>
    </>
  );
};
