import { useMemo } from 'react';
import { CellProps } from 'react-table';
import { WorkOrder } from 'types';
import { getFullName, toEnUsFormat } from 'utils';

import { links } from 'App';

export const useWorkOrdersTableColumns = () => {
  const columns = useMemo(
    () => [
      {
        id: 'due date',
        Header: 'Due date',
        minWidth: 120,
        maxWidth: 120,
        getLink: (row: WorkOrder) => links.WorkOrderOverview({ id: row.id }),
        Cell: ({
          row: {
            original: { dueDate },
          },
        }: CellProps<WorkOrder>) => <>{toEnUsFormat(dueDate)}</>,
      },
      {
        id: 'Project name',
        Header: 'Project name',
        minWidth: 200,
        maxWidth: 200,
        getLink: (row: WorkOrder) => links.WorkOrderOverview({ id: row.id }),
        Cell: ({
          row: {
            original: { project },
          },
        }: CellProps<WorkOrder>) => <span>{project?.name}</span>,
      },
      {
        id: 'Work Order type',
        Header: 'Work Order type',
        minWidth: 160,
        maxWidth: 160,
        getLink: (row: WorkOrder) => links.WorkOrderOverview({ id: row.id }),
        Cell: ({
          row: {
            original: { type },
          },
        }: CellProps<WorkOrder>) => <span>{type?.name}</span>,
      },
      {
        id: 'Assignee',
        Header: 'Assignee',
        minWidth: 140,
        maxWidth: 140,
        getLink: (row: WorkOrder) => links.WorkOrderOverview({ id: row.id }),
        Cell: ({
          row: {
            original: { assignee },
          },
        }: CellProps<WorkOrder>) => <>{getFullName(assignee)}</>,
      },
      {
        id: 'Status',
        Header: 'Status',
        minWidth: 120,
        maxWidth: 120,
        getLink: (row: WorkOrder) => links.WorkOrderOverview({ id: row.id }),
        Cell: ({
          row: {
            original: { status },
          },
        }: CellProps<WorkOrder>) => <>{status?.name}</>,
      },
      {
        id: 'Draft status',
        Header: 'Draft status',
        minWidth: 120,
        maxWidth: 120,
        getLink: (row: WorkOrder) => links.WorkOrderOverview({ id: row.id }),
        Cell: ({
          row: {
            original: { reportStatus },
          },
        }: CellProps<WorkOrder>) => <span className="capitalize">{reportStatus?.replace(/_/g, ' ')}</span>,
      },
      {
        id: 'Drafter',
        Header: 'Drafter',
        minWidth: 150,
        maxWidth: 150,
        getLink: (row: WorkOrder) => links.WorkOrderOverview({ id: row.id }),
        Cell: ({
          row: {
            original: { drafter },
          },
        }: CellProps<WorkOrder>) => <>{getFullName(drafter)}</>,
      },
      {
        id: 'Bank',
        Header: 'Bank',
        minWidth: 150,
        maxWidth: 150,
        getLink: (row: WorkOrder) => links.WorkOrderOverview({ id: row.id }),
        Cell: ({
          row: {
            original: { bank },
          },
        }: CellProps<WorkOrder>) => <>{bank?.name}</>,
      },
      {
        id: 'Bank Contact',
        Header: 'Bank Contact',
        minWidth: 150,
        maxWidth: 150,
        getLink: (row: WorkOrder) => links.WorkOrderOverview({ id: row.id }),
        Cell: ({
          row: {
            original: { bankContact },
          },
        }: CellProps<WorkOrder>) => <>{getFullName(bankContact)}</>,
      },
    ],
    [],
  );

  return {
    columns,
  };
};
