import { TextField } from 'formik-material-ui';
import { CustomDatePicker, Autocomplete } from 'components/common';
import { Field } from 'formik';

import {
  YesNoNaOptions,
  defaultFieldProps,
  designAgreementsExecutedOptions,
  designAgreementsFormOptions,
} from '../const';

export const DesignAgreementsFields = () => (
  <>
    <Field component={TextField} name="architectFirm" label="Design Firm" {...defaultFieldProps} />
    <Field component={CustomDatePicker} name="date" label="Date" removeTimezone {...defaultFieldProps} />
    <Field
      component={Autocomplete}
      name="typeOfAgreement"
      placeholder="What type of Agreement Form is used?"
      options={designAgreementsFormOptions}
      {...defaultFieldProps}
    />
    <Field
      component={TextField}
      name="scopeOfWork"
      label="What is the scope of work shown in the agreement?"
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="executedParties"
      placeholder="Was the agreement executed by both parties?"
      options={designAgreementsExecutedOptions}
      {...defaultFieldProps}
    />
    <Field
      component={Autocomplete}
      name="contractAdministration"
      placeholder="Are Contract Administration Duties included in the agreement?"
      options={YesNoNaOptions}
      {...defaultFieldProps}
    />
  </>
);
