import { FC, RefObject, useEffect, useRef, useState } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Editor as TinyMCEEditor } from 'tinymce';
import { EditIcon } from 'icons';
import clsx from 'clsx';

import styles from './ReportRichEditor.module.scss';

type ReportRichEditorProps = {
  value: string;
  onChange: (value: string) => void;
  isObservation?: boolean;
  addRef?: (ref: RefObject<TinyMCEEditor>) => void;
  hasImage?: boolean;
  disabled?: boolean;
};

export type RefProps = TinyMCEEditor;

//eslint-disable-next-line
export const ReportRichEditor: FC<ReportRichEditorProps> = ({
  value,
  onChange,
  isObservation,
  addRef,
  hasImage,
  disabled,
}) => {
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const [allowEdit, setAllowEdit] = useState(false);

  // const [input, setInput] = useState(value ?? '');

  useEffect(() => {
    addRef && addRef(editorRef);
  }, [onChange]);

  return (
    <div className={clsx('mb-16 p-relative', styles.wrapper)}>
      {!isObservation && (
        <div className={styles.editIcon}>
          <EditIcon onClick={() => setAllowEdit(!allowEdit)} />
        </div>
      )}
      <Editor
        apiKey="hl1isxggsat8729yjt32vvc51ru4gqp95xwbgqryi8p869uq"
        onInit={(evt, editor) => {
          editorRef.current = editor;
        }}
        onBlur={(_, editor) => {
          // setInput(editor.getContent());
          onChange(editor.getContent());
        }}
        initialValue={value}
        disabled={!isObservation && !allowEdit}
        init={{
          width: '100%',
          menubar: true,
          min_height: 250,
          max_height: 500,
          plugins: [
            'autoresize',
            hasImage ? 'image' : '',
            'advlist',
            'tinymcespellchecker',
            'autolink',
            'lists',
            'link',
            'charmap',
            'preview',
            'anchor',
            'searchreplace',
            'visualblocks',
            'code',
            'fullscreen',
            'insertdatetime',
            'media',
            'table',
            'help',
            'wordcount',
            'save',
            'powerpaste',
          ],
          toolbar:
            'undo redo | styles | bold italic | underline alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent | spellchecker',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          valid_elements: '*[*]',
          save_onsavecallback: function () {
            console.log('Saved');
          },
          init_instance_callback: (editor) => {
            disabled && editor.getBody().setAttribute('contenteditable', 'false');
          },
          scroll_restore_exact: false,
          image_title: true,
          /* enable automatic uploads of images represented by blob or data URIs*/
          automatic_uploads: true,
          file_picker_types: 'image',
          /* and here's our custom image picker*/
          file_picker_callback: function (cb) {
            const input = document.createElement('input');
            input.setAttribute('type', 'file');
            input.setAttribute('accept', 'image/*');

            /*
      Note: In modern browsers input[type="file"] is functional without
      even adding it to the DOM, but that might not be the case in some older
      or quirky browsers like IE, so you might want to add it to the DOM
      just in case, and visually hide it. And do not forget do remove it
      once you do not need it anymore.
    */

            input.onchange = function () {
              //eslint-disable-next-line
              const file = (this as any).files[0];

              const reader = new FileReader();
              reader.onload = function () {
                /*
          Note: Now we need to register the blob in TinyMCEs image blob
          registry. In the next release this part hopefully won't be
          necessary, as we are looking to handle it internally.
        */
                const id = 'blobid' + new Date().getTime();
                if (editorRef.current) {
                  const blobCache = editorRef.current.editorUpload.blobCache;
                  if (reader && reader.result) {
                    const base64 = (reader.result as string).split(',')[1];
                    const blobInfo = blobCache.create(id, file, base64);
                    blobCache.add(blobInfo);

                    /* call the callback and populate the Title field with the file name */
                    cb(blobInfo.blobUri(), { title: file.name });
                  }
                }
              };
              reader.readAsDataURL(file);
            };

            input.click();
          },
        }}
      />
    </div>
  );
};
