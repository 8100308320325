import { FC, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

import { CustomDropdown, DatePickerContainer, FilterChip, PageFilters, SearchSelect } from 'components';
import { useAuth } from 'contexts';
import { Country, DropdownOption, GetRouteProps, GridApiResponse, PermissionEnum } from 'types';
import { getDateWithoutTimeZone, getFullName, toDropdownObject, toMonthYYFormat } from 'utils';
import { components } from 'generated/types';
import { WorkOrdersRoute } from 'pages';
import { api, apiRoutes } from 'api';
import { emptyTableData } from 'consts';

import styles from './WorkOrderFilters.module.scss';

import { links } from 'App';
import { ArchiveIcon } from 'icons';
import { NavLink } from 'react-router-dom';
import useWindowSize from 'hooks/useWindowSize';
import { useGetWorkOrderStatuses } from 'lib/react-query/queries';

type Contact = components['schemas']['Contact'];
type User = components['schemas']['User'];

type WorkOrderFiltersProps = Pick<GetRouteProps<typeof WorkOrdersRoute>, 'match' | 'link'>;

export const WorkOrderFilters: FC<WorkOrderFiltersProps> = ({
  match: {
    query: {
      project,
      type,
      assignee,
      bankContact,
      bank,
      dateStart,
      dateEnd,
      state,
      status,
      reportStatus,
      drafter,
      isTableView = false,
    },
    query,
  },
  link,
}) => {
  const { push } = useHistory();
  const { userData } = useAuth();
  const { isMobile } = useWindowSize();

  type QueryKeys = keyof typeof query;

  const [filterDateStart, setFilterDateStart] = useState(dateStart);
  const [filterDateEnd, setFilterDateEnd] = useState(dateEnd);

  const workOrderTypesQuery = () => api.get<DropdownOption[]>(apiRoutes.workOrderTypes).then((res) => res.data);
  const { isLoading: typesLoading, data: types } = useQuery('workOrderTypesQuery', () => workOrderTypesQuery());

  const workOrderReportStatusesQuery = () =>
    api.get<DropdownOption[]>(apiRoutes.workOrderReportStatuses).then((res) => res.data);
  const { isLoading: reportStatusesLoading, data: reportStatuses } = useQuery('workOrderReportStatusesQuery', () =>
    workOrderReportStatusesQuery(),
  );

  const bankContactsQuery = () =>
    api
      .get(apiRoutes.contacts, {
        params: {
          'sort[firstName]': 'ASC',
          'filter[companyType]': 'bank',
          'filter[_dropdown]': true,
        },
      })
      .then((res) => res.data);
  const { isLoading: bankContactLoading, data: bankContacts } = useQuery('bankContactsQuery', () =>
    bankContactsQuery(),
  );

  const projectsQuery = () =>
    api
      .get<DropdownOption[]>(apiRoutes.projects, {
        params: {
          'filter[_dropdown]': true,
          'sort[name]': 'ASC',
        },
      })
      .then((res) => res.data);
  const { data: projects, isLoading: projectsLoading } = useQuery('projectsQuery', () => projectsQuery());

  const organizationsQuery = () =>
    api
      .get<DropdownOption[]>(apiRoutes.organization, {
        params: {
          'filter[typeId]': 'bank',
          'sort[name]': 'ASC',
        },
      })
      .then((res) => res.data);
  const { data: organizations, isLoading: organizationsLoading } = useQuery('organizationsQuery', () =>
    organizationsQuery(),
  );

  const usersQuery = () => api.get<GridApiResponse<User>>(apiRoutes.users).then((res) => res.data);
  const { isLoading: usersLoading, data: users } = useQuery(['usersQuery', query], () => usersQuery());

  const statesQuery = () =>
    axios
      .get(
        'https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-state-millesime&q=&sort=ste_name&rows=100&facet=year&facet=ste_code&facet=ste_name&facet=ste_type&refine.year=2022',
      )
      .then((res) => res.data);
  const { data: states, isLoading: statesLoading } = useQuery('statesQuery', () => statesQuery());

  const { data: workOrderStatuses } = useGetWorkOrderStatuses();

  const handleQueryParamsChange = (key: QueryKeys, value: string) =>
    //eslint-disable-next-line
    push(link({ ...(({ page, ...o }) => o)({ ...query, [key]: value }) }));

  //eslint-disable-next-line
  const handleFilterProjectRemove = () => push(link({ ...(({ project, page, ...o }) => o)({ ...query }) }));
  //eslint-disable-next-line
  const handleFilterTypeRemove = () => push(link({ ...(({ type, page, ...o }) => o)({ ...query }) }));
  //eslint-disable-next-line
  const handleFilterReportStatusRemove = () => push(link({ ...(({ reportStatus, page, ...o }) => o)({ ...query }) }));
  //eslint-disable-next-line
  const handleFilterDrafterRemove = () => push(link({ ...(({ drafter, page, ...o }) => o)({ ...query }) }));
  //eslint-disable-next-line
  const handleFilterAssigneeRemove = () => push(link({ ...(({ assignee, page, ...o }) => o)({ ...query }) }));
  //eslint-disable-next-line
  const handleBankContactRemove = () => push(link({ ...(({ bankContact, page, ...o }) => o)({ ...query }) }));
  //eslint-disable-next-line
  const handleFilterBankRemove = () => push(link({ ...(({ bank, page, ...o }) => o)({ ...query }) }));
  //eslint-disable-next-line
  const handleFilterStateRemove = () => push(link({ ...(({ state, page, ...o }) => o)({ ...query }) }));
  //eslint-disable-next-line
  const handleFilterStatusRemove = () => push(link({ ...(({ status, page, ...o }) => o)({ ...query }) }));

  const handleDateFilterChange = (value: boolean) => {
    // eslint-disable-next-line
    query = value ? query : (({ dateEnd, ...item }) => ({ ...item }))({ ...query });

    push(
      link({
        //eslint-disable-next-line
        ...(({ page, ...o }) => o)({ ...query, dateStart: filterDateStart, ...(value && { dateEnd: filterDateEnd }) }),
      }),
    );
  };

  const handleFilterDateRemove = () => {
    //eslint-disable-next-line
    push(link({ ...(({ dateStart, dateEnd, ...o }) => o)(query) }));
  };

  const handleFilterStartDateChange = (startDate: Date | null) =>
    setFilterDateStart(getDateWithoutTimeZone(startDate).toString());

  const handleFilterEndDateChange = (endDate: Date | null) =>
    setFilterDateEnd(getDateWithoutTimeZone(endDate).toString());

  const handleClear = () => {
    handleFilterDateRemove();
    push(link());
  };

  const filteredProjects = useMemo(() => (projects?.length ? projects : []), [projects]);
  const filteredTypes = useMemo(() => (types?.length ? types : []), [types]);
  const filteredReportStatuses = useMemo(() => (reportStatuses?.length ? reportStatuses : []), [reportStatuses]);
  const filteredUsers = useMemo(() => (users?.items?.length ? users : emptyTableData), [users]);
  const filteredBankContact = useMemo(() => (bankContacts?.length ? bankContacts : []), [bankContacts]);
  const filteredOrganizations = useMemo(() => (organizations?.length ? organizations : []), [organizations]);
  const mappedStates = useMemo(
    () =>
      states?.records?.length
        ? //eslint-disable-next-line
          states?.records.map((el: any, i: number) => ({ ...el, name: el.fields['ste_name'], id: i }))
        : [],
    [states],
  );

  const isFiltersApplied = project || type || assignee || bankContact || bank || dateStart || dateEnd || state;

  return (
    <PageFilters
      showMobileClearBtn={!!Object.keys(query).length}
      handleClear={handleClear}
      isFiltersApplied={isFiltersApplied}
      Filters={
        <>
          <CustomDropdown label="Date" fullWidth={isMobile}>
            {(handleClose) => (
              <DatePickerContainer
                title="Due date"
                isRange={!!dateEnd}
                startDate={filterDateStart}
                endDate={filterDateEnd}
                handleStartDateChange={handleFilterStartDateChange}
                handleEndDateChange={handleFilterEndDateChange}
                handleClose={handleClose}
                applyFilter={(value) => handleDateFilterChange(value)}
              />
            )}
          </CustomDropdown>
          {userData?.permissions.has(PermissionEnum.work_order_filter_by_project) && (
            <SearchSelect
              fullWidth={isMobile}
              loading={projectsLoading}
              label="Project"
              options={toDropdownObject(filteredProjects)}
              onChange={(value) => handleQueryParamsChange('project', value)}
            />
          )}
          {userData?.permissions.has(PermissionEnum.work_order_filter_by_type) && (
            <SearchSelect
              fullWidth={isMobile}
              loading={typesLoading}
              label="Type"
              options={toDropdownObject(filteredTypes)}
              onChange={(value) => handleQueryParamsChange('type', value)}
            />
          )}
          <SearchSelect
            fullWidth={isMobile}
            loading={reportStatusesLoading}
            label="Draft status"
            options={toDropdownObject(filteredReportStatuses)}
            onChange={(value) => handleQueryParamsChange('reportStatus', value)}
          />
          <SearchSelect
            fullWidth={isMobile}
            label="Drafter"
            loading={usersLoading}
            options={toDropdownObject(
              filteredUsers?.items?.map((user) => ({
                ...user,
                name: getFullName(user),
              })),
            )}
            onChange={(value) => handleQueryParamsChange('drafter', value)}
          />
          <SearchSelect
            fullWidth={isMobile}
            label="Assignee"
            loading={usersLoading}
            options={toDropdownObject(
              filteredUsers?.items?.map((user) => ({
                ...user,
                name: getFullName(user),
              })),
            )}
            onChange={(value) => handleQueryParamsChange('assignee', value)}
          />
          <SearchSelect
            fullWidth={isMobile}
            label="Bank"
            loading={organizationsLoading}
            options={toDropdownObject(filteredOrganizations)}
            onChange={(value) => handleQueryParamsChange('bank', value)}
          />
          <SearchSelect
            fullWidth={isMobile}
            label="Bank contact"
            loading={bankContactLoading}
            options={toDropdownObject(
              filteredBankContact?.map((user: Contact) => ({
                ...user,
                name: getFullName(user),
              })),
            )}
            onChange={(value) => handleQueryParamsChange('bankContact', value)}
          />
          <SearchSelect
            fullWidth={isMobile}
            label="State"
            loading={statesLoading}
            options={toDropdownObject(
              (mappedStates as Country[]).map((item) => ({
                ...item,
                label: item.id,
                value: item.name,
              })),
            )}
            onChange={(_, name) => handleQueryParamsChange('state', name as string)}
          />
          {isTableView && (
            <SearchSelect
              fullWidth={isMobile}
              label="Status"
              options={toDropdownObject(workOrderStatuses as DropdownOption[])}
              onChange={(value) => handleQueryParamsChange('status', value as string)}
            />
          )}
        </>
      }
      Chips={
        <>
          {dateStart && (
            <FilterChip
              label={
                dateEnd
                  ? `${toMonthYYFormat(filterDateStart)} - ${toMonthYYFormat(filterDateEnd)}`
                  : toMonthYYFormat(filterDateStart)
              }
              onDelete={handleFilterDateRemove}
            />
          )}
          {project && (
            <FilterChip
              filter="Project"
              label={filteredProjects.find(({ id }) => id === +project)?.name}
              onDelete={handleFilterProjectRemove}
            />
          )}
          {type && (
            <FilterChip
              filter="Type"
              label={types?.find(({ id }) => id === type)?.name}
              onDelete={handleFilterTypeRemove}
            />
          )}

          {reportStatus && (
            <FilterChip
              filter="Draft status"
              label={reportStatuses?.find(({ id }) => id === reportStatus)?.name}
              onDelete={handleFilterReportStatusRemove}
            />
          )}

          {drafter && (
            <FilterChip
              filter="Drafter"
              label={getFullName(filteredUsers?.items?.find(({ id }) => id === +drafter))}
              onDelete={handleFilterDrafterRemove}
            />
          )}

          {bankContact && (
            <FilterChip
              filter="Bank contact"
              label={getFullName(filteredBankContact?.find(({ id }: { id: number }) => id === +bankContact))}
              onDelete={handleBankContactRemove}
            />
          )}

          {assignee && (
            <FilterChip
              filter="Assignee"
              label={getFullName(filteredUsers?.items?.find(({ id }) => id === +assignee))}
              onDelete={handleFilterAssigneeRemove}
            />
          )}
          {bank && (
            <FilterChip
              filter="Bank"
              label={organizations && organizations.find(({ id }) => id === +bank)?.name}
              onDelete={handleFilterBankRemove}
            />
          )}
          {state && <FilterChip filter="State" label={state} onDelete={handleFilterStateRemove} />}
          {status && isTableView && (
            <FilterChip
              filter="Status"
              label={workOrderStatuses?.find(({ id }) => id === status)?.name}
              onDelete={handleFilterStatusRemove}
            />
          )}
        </>
      }
    >
      <NavLink
        to={links.ArchivedWorkOrders()}
        className="mb-0 mb-sm-12 ml-0 ml-sm-12 uppercase text-primary weight-700 text-14"
      >
        <ArchiveIcon className={styles.archiveIcon} />
        Archive
      </NavLink>
    </PageFilters>
  );
};
