import { lazy } from 'react';
import { Route } from 'react-router-hoc';

export const WorkOrdersRoute = Route(
  {
    project: Route.query.string,
    search: Route.query.string,
    type: Route.query.string,
    assignee: Route.query.string,
    bankContact: Route.query.string,
    bank: Route.query.string,
    state: Route.query.string,
    page: Route.query.number,
    dateStart: Route.query.string,
    dateEnd: Route.query.string,
    status: Route.query.string,
    reportStatus: Route.query.string,
    drafter: Route.query.string,
    isTableView: Route.query.boolean,
  },
  '/work-orders',
);

export const WorkOrders = WorkOrdersRoute(lazy(() => import('./WorkOrders')));
