import { PropsWithChildren } from 'react';
import clsx from 'clsx';
import { NavLink } from 'react-router-dom';
import { ArchiveIcon, ArrowDownIcon, DeleteIcon, EditIcon } from 'icons';

import styles from './Row.module.scss';
import { RowFieldProps, RowProps } from './types';

export const MobileTableRowField = ({ label, value, link }: RowFieldProps) => {
  return (
    <div className="pt-8">
      <span className="uppercase text-black">{label}:</span>
      {link ? <NavLink to={link}>{value}</NavLink> : <span>{value}</span>}
    </div>
  );
};

export const MobileTableRow = ({
  id,
  link,
  children,
  selectedRowInfo,
  handleToggle,
  handleEdit,
  handleDelete,
  handleArchive,
  rowTitle,
}: PropsWithChildren<RowProps>) => {
  const isOpenDropdown = selectedRowInfo === id;

  return (
    <div className={styles.rowContainer}>
      <div className="flex align-items-center justify-content-between" onClick={() => handleToggle(id as number)}>
        {link ? (
          <NavLink to={link}>
            <p>{rowTitle}</p>
          </NavLink>
        ) : (
          <p>{rowTitle}</p>
        )}

        <ArrowDownIcon className={clsx(styles.arrowDownIcon, isOpenDropdown && styles.arrowDownIconRotate)} />
      </div>
      {isOpenDropdown && (
        <div className="flex gap-16 flex-column pt-16">
          {children}

          <div className="flex align-items-center">
            {handleEdit && (
              <div className={styles.actionContainer} onClick={() => handleEdit(id as number)}>
                <EditIcon />
                Edit
              </div>
            )}

            {handleDelete && (
              <div className={styles.actionContainer} onClick={() => handleDelete(id as number)}>
                <DeleteIcon />
                Delete
              </div>
            )}

            {handleArchive && (
              <div className={styles.actionContainer} onClick={() => handleArchive(id as number)}>
                <ArchiveIcon />
                Archive
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
