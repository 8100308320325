import {
  ReportTypesEnum,
  Report,
  DropdownOption,
  Contact,
  Organization,
  ProjectStatusEnum,
  WorkOrderTypeEnum,
  SortOptionEnum,
} from 'types';
import { getFullName } from './getFullName';
import { ProposalStatusEnum } from 'types/proposal';
type FieldsWithDiffrentSourceReportData = 'projectName' | 'assignee' | 'date' | 'workOrderStatus';

export const getProjectByReportType = (report: Report) => {
  switch (report?.type) {
    case ReportTypesEnum.PreConstruction:
      return report?.workOrder?.project;
    case ReportTypesEnum.Inspection:
      return report?.inspection?.project;
  }
};

export const getLabelByReportTypesEnum = (reportType: ReportTypesEnum) => {
  switch (reportType) {
    case ReportTypesEnum.PreConstruction:
      return 'Pre-construction review';
    case ReportTypesEnum.Inspection:
      return 'Inspection report';
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getFieldDataByReportType = (report: Report, field: FieldsWithDiffrentSourceReportData): any => {
  switch (report.type) {
    case ReportTypesEnum.PreConstruction:
      return getFieldDataPreConstructionReport(report, field);
    case ReportTypesEnum.Inspection:
      return getFieldDataInspectionReport(report, field);
  }
};

export const getFieldDataPreConstructionReport = (report: Report, field: FieldsWithDiffrentSourceReportData) => {
  switch (field) {
    case 'projectName':
      return report.workOrder?.project?.name;
    case 'assignee':
      return report.workOrder?.assignee;
    case 'date':
      return report.workOrder?.dueDate;
    case 'workOrderStatus':
      return report.workOrder?.status;
  }
};

export const getFieldDataInspectionReport = (report: Report, field: FieldsWithDiffrentSourceReportData) => {
  switch (field) {
    case 'projectName':
      return report.inspection?.project?.name;
    case 'assignee':
      return report.inspection?.owner;
    case 'date':
      return report.inspection?.workOrder?.dueDate;
    case 'workOrderStatus':
      return report?.inspection?.workOrder?.status;
  }
};

export const getDefaultSectionByReportType = (reportType: ReportTypesEnum): string => {
  switch (reportType) {
    case ReportTypesEnum.PreConstruction:
      return '31';
    case ReportTypesEnum.Inspection:
      return '37';
    default:
      return '37';
  }
};

export const getOptionName = ({ name }: DropdownOption) => name ?? '';
export const getOptionSelected = (option: DropdownOption, value: DropdownOption) => option.id === value.id;
export const getEntityOptionName = (user: Contact | Organization) => {
  if ('firstName' in user) {
    return getFullName(user);
  } else {
    return (user as Organization).name ?? '';
  }
};

//eslint-disable-next-line
export const getMappedStates = (countries: any) => {
  if (!countries?.records?.length) return [];
  //eslint-disable-next-line
  return countries?.records.map((el: any, i: number) => ({ ...el, name: el.fields['ste_name'], id: i }));
};

//eslint-disable-next-line
export const getMappedCities = (cities: any, selectedState: string) => {
  if (!cities?.records.length)
    return selectedState === 'District of Columbia'
      ? [
          {
            id: Math.random(),
            name: selectedState,
            geometry: { coordinates: [-77.01583889168275, 38.90534520465281] },
          },
        ]
      : [];

  //eslint-disable-next-line
  return cities?.records.map((item: any) => ({ ...item, id: item.fields.coty_code, name: item.fields.pla_name }));
};

export const getUpdatedSortingOptions = (
  option: string,
  options: { [x: string]: string | null },
  defaultSortField: string,
  defaultSortOrder = SortOptionEnum.DESC,
) => {
  const updatedOptions = Object.keys(options).reduce((acc, key) => {
    if (key === option) {
      if (options[key] === null) {
        acc[key] = SortOptionEnum.ASC;
      } else if (options[key] === SortOptionEnum.ASC) {
        acc[key] = SortOptionEnum.DESC;
      } else {
        acc[key] = null;
      }
    } else {
      acc[key] = null;
    }
    return acc;
  }, {} as { [x: string]: string | null });

  if (Object.values(updatedOptions).every((value) => value === null)) {
    updatedOptions[defaultSortField] = defaultSortOrder;
  }

  return updatedOptions;
};

export const getLabelByProposalStatusesEnum = (status: ProposalStatusEnum) => {
  switch (status) {
    case ProposalStatusEnum.ToDo:
      return 'To Do';
    case ProposalStatusEnum.InProgress:
      return 'In Progress';
    case ProposalStatusEnum.InReview:
      return 'In Review';
    case ProposalStatusEnum.Pending:
      return 'Pending';
    case ProposalStatusEnum.InRework:
      return 'In Rework';
    case ProposalStatusEnum.NoResponse:
      return 'No Response';
    case ProposalStatusEnum.Awarded:
      return 'Awarded';
    case ProposalStatusEnum.Rejected:
      return 'Rejected';
    case ProposalStatusEnum.InWork:
      return 'In Work';
    case ProposalStatusEnum.Completed:
      return 'Completed';
    case 'accepted' as ProposalStatusEnum:
      return 'Accepted';
  }
};

export const getLabelByProjectStatusesEnum = (status: ProjectStatusEnum) => {
  switch (status) {
    case ProjectStatusEnum.All:
      return 'All';
    case ProjectStatusEnum.Active:
      return 'Active';
    case ProjectStatusEnum.Waiting:
      return 'Waiting';
    case ProjectStatusEnum.Inactive:
      return 'Inactive';
  }
};

export const getLabelByWorkOrderTypeEnum = (status: WorkOrderTypeEnum) => {
  switch (status) {
    case WorkOrderTypeEnum.ContractorCapabilityEvaluation:
      return 'Contractor capability evaluation';
    case WorkOrderTypeEnum.FundsControl:
      return 'Funds control';
    case WorkOrderTypeEnum.InspectionReport:
      return 'Inspection report';
    case WorkOrderTypeEnum.PreConstructionReview:
      return 'Pre-construction review';
    case WorkOrderTypeEnum.Pca:
      return 'PCA';
    case WorkOrderTypeEnum.AdReport:
      return 'Ad report';
  }
};

export const getProgressColor = (value: number) => {
  if (value <= 50) return '#CC0000';
  else if (value === 100) return '#009933';
  return '#212B83';
};
