import clsx from 'clsx';
import { ColumnViewIcon, TableViewIcon } from 'icons';
import styles from './DataViewIcons.module.scss';
import { useHistory } from 'react-router';

import { DataViewIconsProps } from './types';

export const DataViewIcons = ({ link, match }: DataViewIconsProps) => {
  const { push } = useHistory();
  const { query, params } = match;
  const toggleTableView = () => push(link({ ...query, ...params, isTableView: true }));
  const toggleColumnView = () => push(link({ ...query, ...params, isTableView: false }));
  return (
    <div className="flex justify-content-end">
      <ColumnViewIcon
        className={clsx(styles.viewToggleIcon, !query.isTableView && styles.activeViewToggleIcon)}
        onClick={toggleColumnView}
      />
      <TableViewIcon
        className={clsx(styles.viewToggleIcon, query.isTableView && styles.activeViewToggleIcon)}
        onClick={toggleTableView}
      />
    </div>
  );
};
