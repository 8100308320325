import { useMemo } from 'react';
import { CellProps } from 'react-table';
import commonStyles from 'styles/common.module.scss';
import transformDateToMonthWithYear from 'utils/productivity-metrics/transformDateToMonthWithYear';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ProductivityMetricsData = any;
type Owner = {
  ownerFullName: string;
  countProjects: number;
};

type Category = {
  countProjects?: number;
  percentageTotalProjects?: number;
  owners?: Owner[];
};

export const usePMProjectPortfolioTableColumns = (completedStats: Category) => {
  const completedStatsColumns = useMemo(
    () => [
      {
        id: 'completed',
        Header: '% Completed',
        minWidth: 120,
        maxWidth: 120,
        Cell: ({
          row: {
            original: { percentage },
          },
        }: CellProps<ProductivityMetricsData>) => <>{percentage}%</>,
      },
      {
        id: 'count',
        Header: 'Active Projects',
        minWidth: 140,
        maxWidth: 140,
        Cell: ({
          row: {
            original: { countProjects },
          },
        }: CellProps<ProductivityMetricsData>) => (
          <span className={commonStyles.tableItemEndAlign}>{countProjects}</span>
        ),
      },
      {
        id: 'total projects',
        Header: '% of total projects',
        minWidth: 160,
        maxWidth: 160,
        Cell: ({
          row: {
            original: { percentageTotalProjects },
          },
        }: CellProps<ProductivityMetricsData>) => (
          <span className={commonStyles.tableItemEndAlign}>{percentageTotalProjects}%</span>
        ),
      },
    ],
    [],
  );

  const ownerColumns = useMemo(() => {
    if (!completedStats) {
      return [];
    }

    const uniqueOwners = Array.from(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.values(completedStats).reduce((acc: Set<string>, category: any) => {
        if (category.owners) {
          category.owners.forEach((owner: Owner) => acc.add(owner.ownerFullName));
        }

        return acc;
      }, new Set<string>()),
    );

    return uniqueOwners.map((ownerName: string) => ({
      id: ownerName,
      Header: ownerName,
      minWidth: 160,
      maxWidth: 160,
      Cell: ({
        row: {
          original: { owners },
        },
      }: CellProps<ProductivityMetricsData>) => {
        const ownerData = owners.find((owner: Owner) => owner.ownerFullName === ownerName);

        return <span className={commonStyles.tableItemEndAlign}>{ownerData ? ownerData.countProjects : 0}</span>;
      },
    }));
  }, [completedStats]);

  const allColumns = useMemo(() => [...completedStatsColumns, ...ownerColumns], [completedStatsColumns, ownerColumns]);

  const anticipatedStatsColumns = useMemo(
    () => [
      {
        id: 'month',
        Header: 'Month',
        minWidth: 160,
        maxWidth: 160,
        Cell: ({
          row: {
            original: { date },
          },
        }: CellProps<ProductivityMetricsData>) => <>{transformDateToMonthWithYear(date)}</>,
      },
      {
        id: 'anticipated projects',
        Header: 'Projects Likely to Begin',
        minWidth: 190,
        maxWidth: 190,
        Cell: ({
          row: {
            original: { anticipatedProjects },
          },
        }: CellProps<ProductivityMetricsData>) => (
          <span className={commonStyles.tableItemEndAlign}>{anticipatedProjects}</span>
        ),
      },
      {
        id: 'anticipated completing projects',
        Header: 'Projects Likely to Finish',
        minWidth: 190,
        maxWidth: 190,
        Cell: ({
          row: {
            original: { anticipatedCompletedProjects },
          },
        }: CellProps<ProductivityMetricsData>) => (
          <span className={commonStyles.tableItemEndAlign}>{anticipatedCompletedProjects}</span>
        ),
      },
      {
        id: 'variance',
        Header: 'Variance',
        minWidth: 100,
        maxWidth: 100,
        Cell: ({
          row: {
            original: { variance },
          },
        }: CellProps<ProductivityMetricsData>) => <span className={commonStyles.tableItemEndAlign}>{variance}</span>,
      },
    ],
    [],
  );

  return {
    completedStatsColumns: allColumns,
    anticipatedStatsColumns,
  };
};
