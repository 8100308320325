import { api, apiRoutes } from 'api';
import { useQuery } from 'react-query';
import { DropdownOption, GridApiResponse, SortOptionEnum } from 'types';
import {
  BankContact,
  DocumentCategory,
  Note,
  Organization,
  Project,
  ProjectCoverTabDTO,
  Proposal,
  Report,
  User,
  WorkOrder,
  Inspection,
} from 'types/common';
import { QueryParameters } from './types';
import { PROJECT_DOCUMENTS_QUERY_KEY, queriesKeys } from './const';
import axios from 'axios';
import { NoteTabCount } from 'types/notes';
import { emptyTableData } from 'consts';

export const useGetProjects = (parameters?: QueryParameters) => {
  const { params, options } = parameters || {};
  const projectsQuery = () =>
    api
      .get<DropdownOption[]>(apiRoutes.projects, {
        params: {
          'filter[_dropdown]': true,
          'sort[name]': SortOptionEnum.ASC,
          ...params,
        },
      })
      .then((res) => res.data);

  return useQuery('projectsQuery', () => projectsQuery(), {
    ...options,
    initialData: [],
  });
};

export const useGetProjectById = (id: number, parameters?: QueryParameters) => {
  const { params, options } = parameters || {};
  const projectByIdQuery = () =>
    api
      .get<Project>(`${apiRoutes.projects}/${id}`, {
        params,
      })
      .then((res) => res.data);

  return useQuery(['projectByIdQuery'], () => projectByIdQuery(), {
    ...options,
  });
};

export const useGetProjectTypes = (parameters?: QueryParameters) => {
  const { params, options } = parameters || {};
  const projectTypesQuery = () =>
    api
      .get<DropdownOption[]>(apiRoutes.projectTypes, {
        params: {
          'sort[name]': SortOptionEnum.ASC,
          ...params,
        },
      })
      .then((res) => res.data);

  return useQuery(['projectTypes'], () => projectTypesQuery(), {
    ...options,
    initialData: [],
  });
};

export const useGetInspections = ({ params, options, queryKey }: QueryParameters) => {
  const inspectionsQuery = () =>
    api
      .get<DropdownOption[] | GridApiResponse<Inspection>>(apiRoutes.inspections, {
        params,
      })
      .then((res) => res.data);

  return useQuery(['inspectionsQuery', ...queryKey], () => inspectionsQuery(), {
    ...options,
  });
};

export const useGetUsers = (parameters?: QueryParameters) => {
  const { params, options } = parameters || {};
  const usersQuery = () =>
    api
      .get<GridApiResponse<User> | DropdownOption[]>(apiRoutes.users, {
        params,
      })
      .then((res) => res.data);

  return useQuery(['usersQuery'], () => usersQuery(), {
    ...options,
    initialData: params && params['filter[_dropdown]'] ? [] : emptyTableData,
  });
};

export const useGetWorkOrderStatuses = () => {
  const statusesQuery = () => api.get<DropdownOption[]>(apiRoutes.workOrderStatuses).then((res) => res.data);
  return useQuery(['workOrderStatuses'], () => statusesQuery());
};

export const useGetWorkOrderTypes = () => {
  const typesQuery = () => api.get<DropdownOption[]>(apiRoutes.workOrderTypes).then((res) => res.data);
  return useQuery(['workOrderTypesQuery'], () => typesQuery());
};

export const useGetWorkOrders = ({ params, options, queryKey }: QueryParameters) => {
  const workOrdersQuery = () =>
    api
      .get<DropdownOption[] | GridApiResponse<WorkOrder>>(apiRoutes.workOrders, {
        params,
      })
      .then((res) => res.data);

  return useQuery(['workOrdersQuery', ...queryKey], () => workOrdersQuery(), {
    ...options,
  });
};

export const useGetContacts = (parameters?: QueryParameters) => {
  const { params } = parameters || {};
  const contactsQuery = () =>
    api
      .get<BankContact[]>(apiRoutes.contacts, {
        params: {
          'filter[_dropdown]': true,
          'sort[firstName]': SortOptionEnum.ASC,
          'sort[lastName]': SortOptionEnum.ASC,
          ...params,
        },
      })
      .then((res) => res.data);

  return useQuery(['contactQuery'], () => contactsQuery(), { initialData: [] });
};

export const useGetCompanies = (parameters?: QueryParameters) => {
  const { params } = parameters || {};
  const companiesQuery = () =>
    api
      .get<Organization[]>(apiRoutes.organization, {
        params: {
          'sort[name]': SortOptionEnum.ASC,
          ...params,
        },
      })
      .then((res) => res.data);

  return useQuery(['companiesQuery'], () => companiesQuery(), { initialData: [] });
};

export const useGetProjectNotes = (id: number, parameters?: QueryParameters) => {
  const { params, options } = parameters || {};
  const projectNotesQuery = () =>
    api
      .get<GridApiResponse<Note>>(`${apiRoutes.projects}/${id}/notes`, {
        params: {
          'filter[_documentTrackingNote]': true,
          'sort[createdAt]': SortOptionEnum.DESC,
          ...params,
        },
      })
      .then((res) => res.data);

  return useQuery(['projectNotesQuery'], () => projectNotesQuery(), { ...options });
};

export const useGetProjectDocumentCategories = (id: number, parameters?: QueryParameters) => {
  const { options } = parameters || {};
  const projectDocumentCategoriesQuery = () =>
    api.get<DocumentCategory[]>(`${apiRoutes.projects}/${id}/document-categories`).then((res) => res.data);

  return useQuery([PROJECT_DOCUMENTS_QUERY_KEY], () => projectDocumentCategoriesQuery(), { ...options });
};

export const useGetCountries = (parameters?: QueryParameters) => {
  const { options } = parameters || {};
  const countriesInfoQuery = () =>
    axios
      .get(
        'https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-state-millesime&q=&sort=ste_name&rows=100&facet=year&facet=ste_code&facet=ste_name&facet=ste_type&refine.year=2022',
      )
      .then((res) => res.data);

  return useQuery(['statesQuery'], () => countriesInfoQuery(), { ...options, initialData: [] });
};

export const useGetCities = (selectedState: string, parameters?: QueryParameters) => {
  const { options } = parameters || {};
  const citiesInfoQuery = () =>
    axios
      .get(
        `https://public.opendatasoft.com/api/records/1.0/search/?dataset=georef-united-states-of-america-place&q=&rows=1000&sort=pla_name&facet=year&facet=ste_code&facet=ste_name&facet=coty_code&facet=coty_name&facet=cousub_code&facet=cousub_name&facet=pla_code&facet=pla_name&facet=pla_type&refine.ste_name=${selectedState}`,
      )
      .then((res) => res.data);

  return useQuery(['citiesInfoQuery'], () => citiesInfoQuery(), { ...options });
};

export const useGetReportById = (id: number) => {
  const reportByIdQuery = () => api.get<Report>(`${apiRoutes.reports}/${id}`).then((res) => res.data);
  return useQuery(['reportByIdQuery', id], () => reportByIdQuery());
};

export const useGetContractors = (parameters?: QueryParameters) => {
  const { params, options } = parameters || {};
  const contractorsQuery = () =>
    api
      .get<Organization[]>(apiRoutes.organizations, {
        params: {
          'sort[name]': SortOptionEnum.ASC,
          'filter[typeId]': 'general-contractor',
          'filter[_dropdown]': true,
          ...params,
        },
      })
      .then((res) => res.data);

  return useQuery(['contractorsQuery'], () => contractorsQuery(), { ...options });
};

export const useGetProjectCoverPages = (id: number, parameters?: QueryParameters) => {
  const { params, options } = parameters || {};
  const projectCoverPagesQuery = () =>
    api
      .get<ProjectCoverTabDTO>(`${apiRoutes.projects}/${id}/cover-tab`, {
        params: {
          ...params,
        },
      })
      .then((res) => res.data);

  return useQuery(['projectCoverPagesQuery'], () => projectCoverPagesQuery(), { ...options });
};

export const useGetProjectNotesCount = (id: number, parameters?: QueryParameters) => {
  const { params, options } = parameters || {};
  const projectNotesCountQuery = () =>
    api.get<NoteTabCount>(`${apiRoutes.projects}/${id}/notes-count`, { params: { ...params } }).then((res) => res.data);

  return useQuery(['projectNotesCountQuery'], () => projectNotesCountQuery(), { ...options });
};

export const useGetProposalRequests = (parameters?: QueryParameters) => {
  const { params, options, queryKey } = parameters || {};
  const proposalRequestsQuery = () =>
    api.get<GridApiResponse<Proposal>>(apiRoutes.proposalRequests, { params: { ...params } }).then((res) => res.data);

  return useQuery([queriesKeys.proposalRequests, ...queryKey], () => proposalRequestsQuery(), {
    ...options,
    //@ts-ignore
    initialData: { ...emptyTableData, totalLtv: 0 },
  });
};

export const useGetProposalStatuses = () => {
  const proposalStatusesQuery = () =>
    api.get<DropdownOption[]>(apiRoutes.proposalRequestStatuses).then((res) => res.data);
  return useQuery(['proposalStatusesQuery'], () => proposalStatusesQuery());
};

export const useGetProposalServiceTypes = () => {
  const proposalServiceTypesQuery = () =>
    api.get<DropdownOption[]>(apiRoutes.proposalRequestServiceTypes).then((res) => res.data);
  return useQuery(['proposalServiceTypesQuery'], () => proposalServiceTypesQuery());
};

export const useGetProposalEstimateList = () => {
  const proposalEstimateListQuery = () =>
    api.get<DropdownOption[]>(apiRoutes.proposalEstimateList).then((res) => res.data);
  return useQuery(['proposalEstimateListQuery'], () => proposalEstimateListQuery());
};

export const useGetProposalById = (id: number) => {
  const proposalRequestByIdQuery = () =>
    api.get<Proposal>(`${apiRoutes.proposalRequests}/${id}`).then((res) => res.data);
  return useQuery([queriesKeys.proposalRequestById, id], () => proposalRequestByIdQuery());
};

export const useGetNotesByFilter = (parameters?: QueryParameters) => {
  const { params, options, queryKey } = parameters || {};
  const notesByFilterQuery = () => api.get(apiRoutes.notes, { params: { ...params } }).then((res) => res.data);

  return useQuery(['notesByFilterQuery', ...queryKey], () => notesByFilterQuery(), { ...options });
};

export const useGetFilesByFilter = (parameters?: QueryParameters) => {
  const { params, options, queryKey } = parameters || {};
  const filesByFilterQuery = () =>
    api.get(`${apiRoutes.documents}/all`, { params: { ...params } }).then((res) => res.data);

  return useQuery(['filesByFilterQuery', ...queryKey], () => filesByFilterQuery(), { ...options });
};

export const useGetReportLookBackData = (id: number | null, parameters?: QueryParameters) => {
  const { params, options } = parameters || {};
  const reportLookBackDataQuery = () =>
    api.get(`${apiRoutes.reports}/${id}/look-back`, { params: { ...params } }).then((res) => res.data);

  return useQuery(['reportLookBackDataQuery'], () => reportLookBackDataQuery(), { ...options });
};

export const useGetRoles = () => {
  const rolesQuery = () => api.get(apiRoutes.roles).then((res) => res.data);
  return useQuery('rolesQuery', () => rolesQuery());
};
