import clsx from 'clsx';
import { SortIcon } from 'icons';
import { PropsWithChildren } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import { SortOptionEnum } from 'types';
import styles from './SortTableHeader.module.scss';

type SortTableHeaderProps = {
  sortOption: string | null;
  onClick: () => void;
  tooltipText?: string;
  isUserTriggeredSort?: boolean;
  iconCustomStyle?: string;
  wrapperCustomStyle?: string;
};

export const SortTableHeader = ({
  sortOption,
  onClick,
  children,
  tooltipText,
  isUserTriggeredSort,
  iconCustomStyle,
  wrapperCustomStyle,
}: PropsWithChildren<SortTableHeaderProps>) => {
  return (
    <Tooltip title={tooltipText && sortOption ? tooltipText : ''} placement="top" arrow>
      <div className={wrapperCustomStyle}>
        <span
          onClick={onClick}
          className={clsx(styles.sortHeader, isUserTriggeredSort && sortOption && styles.activeSortHeader)}
        >
          <SortIcon
            className={clsx(
              iconCustomStyle || 'mr-4',
              styles.sortOption,
              sortOption && styles.activeSortOption,
              sortOption === SortOptionEnum.ASC && styles.activeSortOptionRotate,
            )}
          />
          {children}
        </span>
      </div>
    </Tooltip>
  );
};
