import { TableV2 } from 'components';
import { WorkOrdersTableViewProps } from './types';
import { useWorkOrdersTableColumns } from './useTableColumns';
import styles from '../../WorkOrders.module.scss';
import { WorkOrdersMobileTable } from '../MobileTable';

export const WorkOrdersTableView = ({ workOrders, isLoading }: WorkOrdersTableViewProps) => {
  const filteredData = workOrders?.items;
  const { columns } = useWorkOrdersTableColumns();

  return (
    <>
      <TableV2
        columns={columns}
        data={filteredData ?? []}
        loading={isLoading}
        model="work orders"
        className={styles.tableWrap}
      />
      <WorkOrdersMobileTable items={filteredData ?? []} loading={isLoading} />
    </>
  );
};
