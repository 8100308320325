import { useGetContacts, useGetCompanies, useGetProjects, useGetProposalServiceTypes } from 'lib/react-query/queries';
import { useState, useMemo, ChangeEvent } from 'react';
import { useHistory } from 'react-router';
import { getDateWithoutTimeZone, getFullName, toDropdownObject, toMonthYYFormat } from 'utils';
import { CompanyTypesEnum, DropdownOption } from 'types';
import { useProposalRequests } from 'pages/ProposalRequests/context';

import { FiltersProps } from './types';

export const useFilters = ({
  match: {
    query: {
      services,
      bank,
      bankContact,
      project,

      dateReceivedRfpStart,
      dateReceivedRfpEnd,

      dateProposalSentStart,
      dateProposalSentEnd,

      dateAcceptedRpfStart,
      dateAcceptedRpfEnd,

      estimatedInspectionDateStartFrom,
      estimatedInspectionDateStartTo,
    },
    query,
  },
  link,
}: FiltersProps) => {
  const { push } = useHistory();
  const { handleResetPagination } = useProposalRequests();

  const [filterDateEstimatedInspectionStartFrom, setFilterDateEstimatedInspectionStartFrom] = useState(
    estimatedInspectionDateStartFrom,
  );
  const [filterDateEstimatedInspectionStartTo, setFilterDateEstimatedInspectionStartTo] =
    useState(estimatedInspectionDateStartTo);

  const [filterDateReceivedRfpStart, setFilterDateReceivedRfpStart] = useState(dateReceivedRfpStart);
  const [filterDateReceivedRfpEnd, setFilterDateReceivedRfpEnd] = useState(dateReceivedRfpEnd);

  const [filterDateProposalSentStart, setFilterDateProposalSentStart] = useState(dateProposalSentStart);
  const [filterDateProposalSentEnd, setFilterDateProposalSentEnd] = useState(dateProposalSentEnd);

  const [filterDateAcceptedRpfStart, setFilterDateAcceptedRpfStart] = useState(dateAcceptedRpfStart);
  const [filterDateAcceptedRpfEnd, setFilterDateAcceptedRpfEnd] = useState(dateAcceptedRpfEnd);

  const isFiltersApplied =
    bank ||
    bankContact ||
    services ||
    dateReceivedRfpStart ||
    dateReceivedRfpEnd ||
    dateProposalSentStart ||
    dateProposalSentEnd ||
    dateAcceptedRpfStart ||
    dateAcceptedRpfEnd;
  const isSortingApplied = 'true';
  const isShowClearBtn = !!Object.values(query).find((el) => !!el);

  const { data: bankContacts, isLoading: bankContactLoading } = useGetContacts({
    params: { 'filter[companyType]': CompanyTypesEnum.Bank },
  });
  const { data: banks, isLoading: banksLoading } = useGetCompanies({
    params: { 'filter[typeId]': CompanyTypesEnum.Bank, 'filter[_dropdown]': true },
  });
  const { data: servicesTypes, isLoading: servicesTypesLoading } = useGetProposalServiceTypes();
  const { data: projects } = useGetProjects({ params: { 'filter[_dropdown]': true } });

  const filteredBankContact = useMemo(() => (bankContacts?.length ? bankContacts : []), [bankContacts]);
  const filteredBanks = useMemo(() => (banks?.length ? banks : []), [banks]);
  const filteredServices = useMemo(() => (servicesTypes?.length ? servicesTypes : []), [servicesTypes]);

  const handleClear = () => {
    push(link());
  };

  type QueryKeys = keyof typeof query;

  const handleSortChange = (value: string) => {
    push(link({ ...(({ ...o }) => o)({ ...query, status: value }) }));
  };

  const handleDateFilterStartChange = (startDate: Date | null, filter?: string) => {
    if (filter === 'estimatedInspectionDateStart')
      return setFilterDateEstimatedInspectionStartFrom(getDateWithoutTimeZone(startDate).toString());
    if (filter === 'receivedRfpDate')
      return setFilterDateReceivedRfpStart(getDateWithoutTimeZone(startDate).toString());
    if (filter === 'proposalSentDate')
      return setFilterDateProposalSentStart(getDateWithoutTimeZone(startDate).toString());
    if (filter === 'acceptedRpfDate')
      return setFilterDateAcceptedRpfStart(getDateWithoutTimeZone(startDate).toString());
  };

  const handleDateFilterEndChange = (endDate: Date | null, filter?: string) => {
    if (filter === 'estimatedInspectionDateStart')
      return setFilterDateEstimatedInspectionStartTo(getDateWithoutTimeZone(endDate).toString());
    if (filter === 'receivedRfpDate') return setFilterDateReceivedRfpEnd(getDateWithoutTimeZone(endDate).toString());
    if (filter === 'proposalSentDate') return setFilterDateProposalSentEnd(getDateWithoutTimeZone(endDate).toString());
    if (filter === 'acceptedRpfDate') return setFilterDateAcceptedRpfEnd(getDateWithoutTimeZone(endDate).toString());
  };

  const handleDateFilterChange = (value: boolean, filter?: string) => {
    handleResetPagination();
    if (filter === 'estimatedInspectionDateStart') {
      return push(
        link({
          ...query,
          estimatedInspectionDateStartFrom: filterDateEstimatedInspectionStartFrom,
          estimatedInspectionDateStartTo: value ? filterDateEstimatedInspectionStartTo : undefined,
          page: undefined,
        }),
      );
    }
    if (filter === 'receivedRfpDate') {
      return push(
        link({
          ...query,
          dateReceivedRfpStart: filterDateReceivedRfpStart,
          dateReceivedRfpEnd: value ? filterDateReceivedRfpEnd : undefined,
          page: undefined,
        }),
      );
    }
    if (filter === 'proposalSentDate') {
      return push(
        link({
          ...query,
          dateProposalSentStart: filterDateProposalSentStart,
          dateProposalSentEnd: value ? filterDateProposalSentEnd : undefined,
          page: undefined,
        }),
      );
    }
    if (filter === 'acceptedRpfDate') {
      return push(
        link({
          ...query,
          dateAcceptedRpfStart: filterDateAcceptedRpfStart,
          dateAcceptedRpfEnd: value ? filterDateAcceptedRpfEnd : undefined,
          page: undefined,
        }),
      );
    }
  };

  const handleFilterDateRemove = (filter?: string) => {
    if (filter === 'estimatedInspectionDateStart')
      push(
        link({
          //eslint-disable-next-line
          ...(({ estimatedInspectionDateStartFrom, estimatedInspectionDateStartTo, ...o }) => o)({
            ...query,
            page: undefined,
          }),
        }),
      );
    if (filter === 'receivedRfpDate')
      push(
        link({
          //eslint-disable-next-line
          ...(({ dateReceivedRfpStart, dateReceivedRfpEnd, ...o }) => o)({
            ...query,
            page: undefined,
          }),
        }),
      );
    if (filter === 'proposalSentDate')
      push(
        link({
          //eslint-disable-next-line
          ...(({ dateProposalSentStart, dateProposalSentEnd, ...o }) => o)({
            ...query,
            page: undefined,
          }),
        }),
      );
    if (filter === 'acceptedRpfDate')
      push(
        link({
          //eslint-disable-next-line
          ...(({ dateAcceptedRpfStart, dateAcceptedRpfEnd, ...o }) => o)({
            ...query,
            page: undefined,
          }),
        }),
      );
  };

  const handleFilterChange = (key: QueryKeys, value: string) => {
    handleResetPagination();
    push(link({ ...(({ ...o }) => o)({ ...query, [key]: value, page: undefined }) }));
  };

  const handleItemsPerGroupChange = (_: ChangeEvent<unknown>, value: DropdownOption | null) => {
    handleResetPagination();
    //eslint-disable-next-line
    push(link({ ...(({ ...o }) => o)({ ...query, itemsPerGroup: value?.id as number }) }));
  };

  const handleFilterRemove = (filterKey: QueryKeys) => {
    const filteredObject = Object.fromEntries(Object.entries(query).filter(([key]) => key !== filterKey));
    push(link({ ...filteredObject, page: undefined }));
  };

  const selectedBankContact = bankContact && getFullName(filteredBankContact.find(({ id }) => id === +bankContact));
  const selectedBank = bank && filteredBanks.find(({ id }) => id === +bank)?.name;
  const selectedProject = project && projects?.find(({ id }) => id === +project)?.name;
  const selectedService = services && servicesTypes?.find(({ id }) => id === services)?.name;
  const selectedEstimatedInspectionStart =
    estimatedInspectionDateStartFrom &&
    (estimatedInspectionDateStartTo
      ? `${toMonthYYFormat(filterDateEstimatedInspectionStartFrom)} - ${toMonthYYFormat(
          estimatedInspectionDateStartTo,
        )}`
      : toMonthYYFormat(filterDateEstimatedInspectionStartFrom));

  const selectedReceivedRfpDate =
    dateReceivedRfpStart &&
    (dateReceivedRfpEnd
      ? `${toMonthYYFormat(filterDateReceivedRfpStart)} - ${toMonthYYFormat(filterDateReceivedRfpEnd)}`
      : toMonthYYFormat(filterDateReceivedRfpStart));
  const selectedProposalSentDate =
    dateProposalSentStart &&
    (dateProposalSentEnd
      ? `${toMonthYYFormat(filterDateProposalSentStart)} - ${toMonthYYFormat(filterDateProposalSentEnd)}`
      : toMonthYYFormat(filterDateProposalSentStart));
  const selectedAcceptedRpfDate =
    dateAcceptedRpfStart &&
    (dateAcceptedRpfEnd
      ? `${toMonthYYFormat(filterDateAcceptedRpfStart)} - ${toMonthYYFormat(filterDateAcceptedRpfEnd)}`
      : toMonthYYFormat(filterDateAcceptedRpfStart));

  const dropdownBanks = toDropdownObject(filteredBanks);
  const dropdownServices = toDropdownObject(filteredServices);
  const dropdownBankContacts = toDropdownObject(
    filteredBankContact?.map((user) => ({
      ...user,
      name: getFullName(user),
    })),
  );
  const dropdownProjects = useMemo(() => (projects?.length ? toDropdownObject(projects) : []), [projects]);

  const itemsPerGroupOptions = [10, 20, 30, 100].map((count) => {
    return { name: `${count} items per group`, id: count };
  });

  return {
    isFiltersApplied,
    isSortingApplied,
    isShowClearBtn,

    filterDateEstimatedInspectionStartFrom,
    filterDateEstimatedInspectionStartTo,

    filterDateAcceptedRpfStart,
    filterDateAcceptedRpfEnd,

    filterDateProposalSentStart,
    filterDateProposalSentEnd,

    filterDateReceivedRfpStart,
    filterDateReceivedRfpEnd,

    servicesTypesLoading,
    bankContactLoading,
    banksLoading,

    selectedBankContact,
    selectedService,
    selectedBank,
    selectedReceivedRfpDate,
    selectedProposalSentDate,
    selectedEstimatedInspectionStart,
    selectedAcceptedRpfDate,
    selectedProject,

    dropdownBanks,
    dropdownServices,
    dropdownBankContacts,
    dropdownProjects,

    itemsPerGroupOptions,

    handleClear,
    handleSortChange,
    handleDateFilterStartChange,
    handleDateFilterEndChange,
    handleDateFilterChange,
    handleFilterDateRemove,
    handleFilterRemove,
    handleFilterChange,

    handleItemsPerGroupChange,
  };
};
